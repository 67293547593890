//Libraries
import React, {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  ChangeEvent,
} from 'react'

//Styles
import './css/inputStyles.module.scss'

interface TelInputTypes {
  name: string
  label: string
  value: string
  setValue: Dispatch<SetStateAction<string>>
  isRequired: boolean
  pattern?: string
  format?: (e: ChangeEvent<HTMLInputElement>) => string
  placeholder?: string
}

const TelInput: FunctionComponent<TelInputTypes> = ({
  name,
  label,
  value,
  setValue,
  pattern,
  format,
  placeholder,
  isRequired,
}) => {
  return (
    <div className="form-group">
      <label>
        {label}
        <input
          id={name}
          name={name}
          type="tel"
          className="form-control"
          value={value}
          onChange={e => {
            format ? setValue(format(e)) : setValue(e.target.value)
          }}
          placeholder={placeholder}
          required={isRequired}
          pattern={pattern}
        />
      </label>
    </div>
  )
}

export default TelInput
