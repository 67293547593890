//Libraries
import React, { FunctionComponent } from 'react'

//Styles
import './css/inputStyles.module.scss'

interface SubmitButtonTypes {
  label: string
}

const SubmitButton: FunctionComponent<SubmitButtonTypes> = ({ label }) => {
  return (
    <button className="btn btn-dark btn-lg" type="submit">
      {label}
    </button>
  )
}

export default SubmitButton
