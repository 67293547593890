//Libraries
import React, { FunctionComponent, useState, FormEvent, useEffect } from 'react'
import axios from 'axios'
import cs from 'classnames'

//Types
import { ContactFormTypes } from '../z_components-types'

//Styles
import styles from './css/contactForm.module.scss'

//Components
import IconWithText from '../free/iconWithText'
import TextInput from '../form/textInput'
import EmailInput from '../form/emailInput'
import TelInput from '../form/telInput'
import TextareaInput from '../form/textareaInput'
import SubmitButton from '../form/submitButton'

//Static
import {
  capEachWord,
  formatPhoneNum,
  capFirstWordSentence,
  removeSpaces,
} from '../../../static/reuseableFunctions'

const ContactForm: FunctionComponent<ContactFormTypes> = ({ data }) => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')
  const [isSuccessful, setIsSuccessful] = useState('loading')
  const [showPopup, setShowPopup] = useState(false)

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    setShowPopup(true)
    e.preventDefault()

    axios
      .post(`/.netlify/functions/submitForm`, {
        firstName,
        lastName,
        phone,
        email,
        message,
      })
      .then(() => {
        setFirstName('')
        setLastName('')
        setEmail('')
        setPhone('')
        setMessage('')
        //Displays the success message for 2 seconds
        setIsSuccessful('success')

        setTimeout(() => {
          setShowPopup(false)
        }, 2000)
        setTimeout(() => {
          setIsSuccessful('loading')
        }, 3000)
      })
      .catch(() => {
        //Displays error message for 2 seconds
        setIsSuccessful('failure')

        setTimeout(() => {
          setShowPopup(false)
        }, 2000)
        setTimeout(() => {
          setIsSuccessful('loading')
        }, 3000)
      })
  }

  useEffect(() => {
    const phoneField: HTMLInputElement | null = document.querySelector('[name=phone]')

    if ( phoneField ) {
      phoneField.oninvalid = () => {
        phoneField.setCustomValidity(
          phoneField.validity.patternMismatch
            ? `Please use the format 555-555-5555`
            : ''
        )
      }
    }
  }, [])

  return (
    <div className={styles.contactContainer}>
      {/* Popup for form submissions */}
      <div className={cs(styles.popupBackground, showPopup && styles.show)}>
        <div className={styles.popupContainer}>
          {isSuccessful !== 'loading' && (
            <>
              <h3>
                {isSuccessful === 'success'
                  ? 'Success!'
                  : `Something went wrong`}
              </h3>
              <h3>{isSuccessful === 'failure' && 'Please try again'}</h3>
            </>
          )}
          <i
            className={cs(
              isSuccessful === 'success'
                ? 'ri-checkbox-circle-fill'
                : isSuccessful === 'failure'
                ? 'ri-close-circle-fill'
                : '',
              isSuccessful === 'success'
                ? styles.success
                : isSuccessful === 'failure'
                ? styles.failure
                : styles.loading
            )}
          ></i>
        </div>
      </div>

      {/* Contact form */}
      <form
        className={styles.form}
        onSubmit={onSubmit}
        name="contact-form"
        action="/"
      >
        <TextInput
          name="firstName"
          label="First Name *"
          value={firstName}
          setValue={setFirstName}
          format={capEachWord}
          maxLength={100}
          isRequired={true}
        />

        <TextInput
          name="lastName"
          label="Last Name *"
          value={lastName}
          setValue={setLastName}
          format={capEachWord}
          maxLength={100}
          isRequired={true}
        />

        <EmailInput
          name="email"
          label="Email *"
          value={email}
          setValue={setEmail}
          format={removeSpaces}
          maxLength={100}
          pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
          isRequired={true}
        />

        <TelInput
          name="phone"
          label="Phone"
          value={phone}
          setValue={setPhone}
          format={formatPhoneNum}
          isRequired={false}
          pattern="^\d{3}-\d{3}-\d{4}$"
        />

        <TextareaInput
          name="message"
          label="Message *"
          value={message}
          setValue={setMessage}
          cols={50}
          rows={15}
          format={capFirstWordSentence}
          isRequired={true}
        />

        <SubmitButton label="Submit" />
      </form>

      {/* Office on aging contact information */}
      <div className={styles.contactInfo}>
        <aside>
          <h3>
            <strong>Physical Address</strong>
          </h3>
          <IconWithText
            icon="ri-home-4-line"
            text={[data.address, data.city]}
          />
        </aside>

        <aside>
          <h3>
            <strong>Mailing Address</strong>
          </h3>
          <IconWithText
            icon="ri-truck-line"
            text={[data.mailingAddress, data.mailingCity]}
          />
        </aside>

        <aside>
          <h3>
            <strong>Email</strong>
          </h3>
          <IconWithText icon="ri-mail-line" emails={[data.email]} />
        </aside>

        <aside>
          <h3>
            <strong>Phone</strong>
          </h3>
          <IconWithText
            icon="ri-phone-line"
            tels={[data.phone, data.fax]}
          />
        </aside>

        <aside>
          <h3>
            <strong>Hours</strong>
          </h3>
          <IconWithText
            icon="ri-time-line"
            text={[data.hoursLineOne, data.hoursLineTwo, data.hoursLineThree]}
          />
        </aside>
      </div>
    </div>
  )
}

export default ContactForm
