//Libraries
import React, { FunctionComponent, ChangeEvent, Dispatch, SetStateAction } from 'react'
import cs from 'classnames'

//Styles
import styles from './css/inputStyles.module.scss'

interface TextareaInputTypes {
  name: string
  label: string
  value: string
  setValue: Dispatch<SetStateAction<string>>
  isRequired: boolean
  cols?: number
  rows?: number
  format?: (e: ChangeEvent<HTMLTextAreaElement>) => string
  placeholder?: string
}

const TextareaInput: FunctionComponent<TextareaInputTypes> = ({
  name,
  label,
  value,
  setValue,
  cols,
  rows,
  format,
  placeholder,
  isRequired,
}) => {
  return (
    <div className={'form-group'}>
      <label>
        {label}
        <textarea
          id={name}
          name={name}
          className={cs('form-control', styles.textarea)}
          cols={cols}
          rows={rows}
          value={value}
          onChange={e =>
            format ? setValue(format(e)) : setValue(e.target.value)
          }
          placeholder={placeholder}
          required={isRequired}
        />
      </label>
    </div>
  )
}

export default TextareaInput
