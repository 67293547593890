//Libraries
import React, { FunctionComponent } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

//Context
import { FontSizeProvider } from '../context/fontSizeContext'

//Types
import { ContactTypes } from './z_page-types'

//Components
import Header from '../components/header/header'
import Footer from '../components/footer/footer'
import Layout from '../components/layout/layout'
import ContactForm from '../components/contact/contactForm'
import PageWelcome from '../components/header/pageWelcome'
import GoogleMap from '../components/free/googleMap'

const Contact: FunctionComponent<ContactTypes> = ({ data }) => {
  const contactData = data.markdownRemark.frontmatter
  return (
    <FontSizeProvider>
      <Helmet htmlAttributes={{lang: 'en'}}>
        <meta charSet="utf-8" />
        <title>OOA - Contact</title>
        <link rel="canonical" href="https://ooa.csi.edu/contact/" />
        <meta
          name="description"
          content="Contact us today for any help you may need!"
        />
      </Helmet>

      <Header />

      <Layout>
        <PageWelcome
          img={contactData.img}
          title={contactData.title}
          imgAltText="Contact banner image"
        />

        <h2 className="center">WE WOULD LOVE TO HEAR FROM YOU!</h2>

        <ContactForm data={contactData} />

        <h2 className="center">HOW TO FIND US</h2>

        <GoogleMap
          lat={contactData.lat}
          lng={contactData.lng}
          title="Office on Aging"
          address="650 Addison Ave W, Twin Falls, ID 83301"
        />
      </Layout>
      <Footer />
    </FontSizeProvider>
  )
}
export default Contact

export const contactQuery = graphql`
  query contactQuery {
    markdownRemark(frontmatter: { template_key: { eq: "contact-page" } }) {
      frontmatter {
        title
        img {
          childImageSharp {
            fluid(maxWidth: 1700, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        address
        city
        lat
        lng
        mailingAddress
        mailingCity
        email
        phone
        fax
        hoursLineOne
        hoursLineTwo
        hoursLineThree
      }
    }
  }
`
