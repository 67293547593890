//Libraries
import React, { FunctionComponent, ChangeEvent, SetStateAction, Dispatch } from 'react'

//Styles
import './css/inputStyles.module.scss'

interface EmailInputTypes {
  name: string
  label: string
  value: string
  setValue: Dispatch<SetStateAction<string>>
  pattern?: string
  format?: (e: ChangeEvent<HTMLInputElement>) => string
  maxLength?: number
  placeholder?: string
  isRequired?: boolean
}

const EmailInput: FunctionComponent<EmailInputTypes> = ({
  name,
  label,
  value,
  setValue,
  pattern,
  format,
  maxLength,
  placeholder,
  isRequired,
}) => {
  return (
    <div className="form-group">
      <label>
        {label}
        <input
          id={name}
          name={name}
          type="email"
          className="form-control"
          value={value}
          onChange={e => {
            format ? setValue(format(e)) : setValue(e.target.value)
          }}
          maxLength={maxLength}
          placeholder={placeholder}
          required={isRequired}
          pattern={pattern}
        />
      </label>
    </div>
  )
}

export default EmailInput
